import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import moment from 'moment';

const UserQuizzesDetails = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleShow = (id) => {
    setUserIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [quizzes, setQuizzes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();

  useEffect(() => {
    $(dataTableRef.current).DataTable();
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    try {
      const response = await axios.get(`${apipath}/quizzes/answers/details`);
      const filteredQuizzes = response.data.quizzes;
      setQuizzes(filteredQuizzes);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/quizzes/answers/details/${userIdToDelete}`);
      await fetchQuizzes(); // Refresh quizzes after deletion
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting quizzes:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className='home'>
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className='bi bi-menu'></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Quizzes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this quizzes?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Quizzes Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                {/* <table id="datatable" className="table" ref={dataTableRef}> */}
                <table id="datatable" className="table" >
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Quizzes Smart Start</th>
                      <th>Joined On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizzes.map((quizze, index) => (
                      <tr key={quizze._id}>
                      <td className='text-center'>{index + 1}</td>
                        <td>{quizze.smartIntellectually}</td>
                        <td>{moment(quizze.createdAt).format('YYYY-MM-DD')}</td>
                        <td>
                        <Link to={`/edit-quizzes/${quizze._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp;
                          <i className="fa-solid fa-trash" onClick={() => handleShow(quizze._id)} style={{ cursor: 'pointer' }}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserQuizzesDetails;
