import iziToast from "izitoast";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const location = useLocation();
 
  const adminToken = localStorage.getItem('admintoken');
  const adminname = localStorage.getItem('adminname');
  const adminemail = localStorage.getItem('adminemail');
  const admintoken = localStorage.getItem('admintoken');
  if(admintoken === null || admintoken === ""){
    navigate("/");
  }

  const handleLogout = () => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('adminId');
    // adminLogout();
    // logout();
    
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    // navigate("/");
    window.location.href = "/";
  };

  const isTabActive = (path) => {
    return location.pathname === path ? 'activeTab' : '';
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <i className="bi bi-menu-down"></i>
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/adminDashboard" className="link">
                <i className="bi bi-grid-3x3"></i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/adminDashboard" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/usersedit" className="link">
                <i className="bi bi-person"></i>
                <span className="name">Users</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Users</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/users-quizzes" className="link">
              <i class="bi bi-question-circle"></i>
                <span className="name">Users Quizzes</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to='/users-quizzes' className="link submenu-title">Users Quizzes</Link>
            </div>
          </li>

          {/* <li
            className={`dropdown ${
              activeSubmenu === "general" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("general");
            }}
          >
            <div className="title">
              <Link className="link">
                <i className="bi bi-gear"></i>
                <span className="name">General Settings</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">General Settings</Link>
              <Link to="/logo" className="link">Logo</Link>
              <Link to="/favicon" className="link">FavIcon</Link>
              <Link to="/navedit" className="link">Navbar</Link> 
              <Link to="/banners" className="link">Banners</Link> 
              <Link to="/footeredit" className="link">Footer</Link>
            </div>
          </li>

          <li className={`dropdown ${
              activeSubmenu === "page" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("page");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-bookshelf"></i>
                <span className="name">Page Settings</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Page Settings</Link>
              <Link to="/homeedit" className="link">Home</Link>
              <Link to="/aboutedit" className="link">About</Link>
              <Link to="/serviceedit" className="link">Service</Link>
              <Link to="/contactedit" className="link">Contact</Link>
              <Link to="/privacyedit" className="link">Privacy</Link>
              <Link to="/termsedit" className="link">Terms & Conditions</Link>
            </div>
          </li>

          <li className={`dropdown ${
              activeSubmenu === "details" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("details");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-gear"></i>
                <span className="name">Details Section</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Details Section</Link>
              <Link to="/detailsedit" className="link">Content</Link>
            </div>
          </li> */}
          <li>
            <div className="title">
              <Link to="/blogsedit" className="link">
                <i className="bi bi-bookmark"></i>
                <span className="name">Blogs</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Blogs</Link>
            </div>
          </li>
          {/* <li className={`dropdown ${
              activeSubmenu === "footer" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("footer");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-bookshelf"></i>
                <span className="name">Footer Section</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Footer Section</Link>
              <Link to="/footerCedit" className="link">Terms Use</Link>
              <Link to="/footerCedit" className="link">Privacy</Link>
            </div>
          </li> */}

          {/* <li className={`dropdown ${
              activeSubmenu === "overview" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("overview");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-bar-chart-steps"></i>
                <span className="name">Courses</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Course Overview</Link>
              <Link to="/courseedit" className="link">Courses</Link>
              <Link to="/courseedit" className="link">Slots</Link>
              <Link to="/courseedit" className="link">Price</Link>
            </div>
          </li> */}


          {/* <li>
            <div className="title">
              <Link to="/profileedit" className="link">
                <i className="bi bi-person"></i>
                <span className="name">Profile</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Profile</Link>
            </div>
          </li> */}

          <li>
            <div className="title">
              <Link to="/faqedit" className="link">
                <i className="bi bi-gear"></i>
                <span className="name">FAQ</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">FAQ</Link>
            </div>
          </li>
          {/* <li>
            <div className="title">
              <Link to="/footeredit" className="link">
                <i className="bi bi-gear"></i>
                <span className="name">Footer</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">FAQ</Link>
            </div>
          </li> */}
          <li className="logout">
            <div className="title">
              <Link to="" className="link">
                <i className="bi bi-box-arrow-left"></i>
                <span className="name" onClick={handleLogout}>Logout</span>
              </Link>
            </div>
            
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
