import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const UserDetailsEdit = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    latitude: '',
    longitude: '',
    status: '',
    // role: '',
    userImages: ['', '', '', ''], // Assuming an array for multiple images
    bio: '',
    interestsReally: '',
    idealPlans: '',
    userHeight: '',
    interestedPerson: '',
    datingGoal: '',
    kids: '',
    language: '',
    smoking: '',
    starSign: '',
    pets: '',
    religion: '',
    educationLevel: '',
    gender: '',
    city: '',
    state: '',
    country: '',
  });

  // Handle file input and text input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      const newImages = [...formData.userImages];
      const index = parseInt(name.replace('userImage', ''), 10) - 1; // Get index from name (e.g., userImage1 -> 0)
      newImages[index] = file;

      setFormData({
        ...formData,
        userImages: newImages,
        [`${name}Preview`]: URL.createObjectURL(file),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${apipath}/users/details/${userId}`);
        const data = response.data.user;
        console.log(data);

        // Map API response to formData
        setFormData(prevData => ({
          ...prevData,
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          phone: data.phone || '',
          latitude: data.latitude || '',
          longitude: data.longitude || '',
          status: data.status ? 'Active' : 'Inactive',
          // role: data.role || '',
          bio: data.bio || '',
          interestsReally: data.interestsReally || '',
          idealPlans: data.idealPlans || '',
          userHeight: data.userHeight || '',
          interestedPerson: data.interestedPerson || '',
          datingGoal: data.datingGoal || '',
          kids: data.kids || '',
          language: data.language || '',
          smoking: data.smoking || '',
          starSign: data.starSign || '',
          pets: data.pets || '',
          religion: data.religion || '',
          educationLevel: data.educationLevel || '',
          gender: data.gender || '',
          city: data.city || '',
          state: data.state || '',
          country: data.country || '',
          userImages: data.userImages || ['', '', '', ''],
        }));
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    fetchUserDetails();
  }, [apipath, userId]);

  const handleUploadButtonClick = (inputId) => {
    document.getElementById(inputId).click();
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit and verify user details</h5>
            <div className="row nav-column">
              <form className="row">
                {/* Fields */}
                {[
                  { label: 'First Name', name: 'firstName', type: 'text' },
                  { label: 'Last Name', name: 'lastName', type: 'text' },
                  { label: 'Email', name: 'email', type: 'email' },
                  { label: 'Phone Number', name: 'phone', type: 'text' },
                  { label: 'Latitude', name: 'latitude', type: 'text' },
                  { label: 'Longitude', name: 'longitude', type: 'text' },
                  { label: 'Driver Status', name: 'status', type: 'text' },
                  { label: 'Gender', name: 'gender', type: 'text' },
                  { label: 'City', name: 'city', type: 'text' },
                  { label: 'State', name: 'state', type: 'text' },
                  { label: 'Country', name: 'country', type: 'text' },
                  { label: 'Bio', name: 'bio', type: 'text' },
                  { label: 'Interests', name: 'interestsReally', type: 'text' },
                  { label: 'Ideal Plans', name: 'idealPlans', type: 'text' },
                  { label: 'Height', name: 'userHeight', type: 'text' },
                  { label: 'Interested Person', name: 'interestedPerson', type: 'text' },
                  { label: 'Dating Goal', name: 'datingGoal', type: 'text' },
                  { label: 'Kids', name: 'kids', type: 'text' },
                  { label: 'Language', name: 'language', type: 'text' },
                  { label: 'Smoking', name: 'smoking', type: 'text' },
                  { label: 'Star Sign', name: 'starSign', type: 'text' },
                  { label: 'Pets', name: 'pets', type: 'text' },
                  { label: 'Religion', name: 'religion', type: 'text' },
                  { label: 'Education Level', name: 'educationLevel', type: 'text' },
                ].map(field => (
                  <div className="col-lg-6 mb-3" key={field.name}>
                    <label className="form-label">{field.label}</label>
                    <input
                      type={field.type}
                      className="form-control"
                      placeholder={`Enter ${field.label.toLowerCase()}`}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      readOnly={field.name !== 'role'}
                    />
                  </div>
                ))}

                {formData.userImages.map((image, index) => (
                  <div className="col-lg-6 mb-3" key={`userImage${index + 1}`}>
                    <div className="change-profile pt-4 pb-4">
                      <img
                        src={formData[`profilePreview${index + 1}`] || (image ? `${image}` : `${process.env.PUBLIC_URL}/imgs/user-icon.png`)}
                        width={90}
                        height={90}
                        style={{ borderRadius: '35px' }}
                        alt={`profile-img-${index + 1}`}
                        onClick={() => handleUploadButtonClick(`fileInput${index + 1}`)}
                      />
                    </div>
                    <input
                      type="file"
                      id={`fileInput${index + 1}`}
                      name={`userImage${index + 1}`}
                      style={{ display: 'none' }}
                      onChange={handleChange}
                    />
                    <label className="form-label">Profile Image {index + 1} Status</label><br />
                  </div>
                ))}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDetailsEdit;
